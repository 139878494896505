var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'vjs-tree': true,
    'has-selectable-control': _vm.isMultiple || _vm.showSelectController,
    'is-root': _vm.currentDeep === 1,
    'is-selectable': _vm.selectable,
    'is-selected': _vm.isSelected,
    'is-highlight-selected': _vm.isSelected && _vm.highlightSelectedNode,
    'is-mouseover': _vm.isMouseover
  },on:{"click":_vm.handleClick,"mouseover":function($event){$event.stopPropagation();return _vm.handleMouseover.apply(null, arguments)},"mouseout":function($event){$event.stopPropagation();return _vm.handleMouseout.apply(null, arguments)}}},[(_vm.showSelectController && _vm.selectable)?[(_vm.isMultiple)?_c('vue-checkbox',{on:{"change":function($event){return _vm.handleValueChange('checkbox')}},model:{value:(_vm.currentCheckboxVal),callback:function ($$v) {_vm.currentCheckboxVal=$$v},expression:"currentCheckboxVal"}}):(_vm.isSingle)?_c('vue-radio',{attrs:{"path":_vm.path},on:{"change":function($event){return _vm.handleValueChange('radio')}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e()]:_vm._e(),(Array.isArray(_vm.data) || _vm.isObject(_vm.data))?[_c('brackets-left',{attrs:{"visible":_vm.visible,"data":_vm.data,"show-length":_vm.showLength,"show-comma":_vm.notLastKey,"can-collapse":_vm.currentDeep > 1},on:{"update:visible":function($event){_vm.visible=$event}}},[(_vm.currentDeep > 1 && !Array.isArray(_vm.parentData))?_c('span',{staticClass:"vjs-key"},[_vm._v(_vm._s(_vm.keyFormatter(_vm.currentKey))+":")]):_vm._e()]),_vm._l((_vm.data),function(item,key){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],key:key,class:{
        'vjs-tree__content': true,
        'has-line': _vm.showLine
      }},[_c('vue-json-pretty',{attrs:{"parent-data":_vm.data,"data":item,"deep":_vm.deep,"show-length":_vm.showLength,"show-double-quotes":_vm.showDoubleQuotes,"show-line":_vm.showLine,"highlight-mouseover-node":_vm.highlightMouseoverNode,"highlight-selected-node":_vm.highlightSelectedNode,"path":_vm.path + (Array.isArray(_vm.data) ? `[${key}]` : `.${key}`),"path-selectable":_vm.pathSelectable,"selectable-type":_vm.selectableType,"show-select-controller":_vm.showSelectController,"select-on-click-node":_vm.selectOnClickNode,"current-key":key,"current-deep":_vm.currentDeep + 1},on:{"click":_vm.handleItemClick,"change":_vm.handleItemChange},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}),_c('brackets-right',{attrs:{"visible":_vm.visible,"data":_vm.data,"show-comma":_vm.notLastKey,"can-collapse":_vm.currentDeep > 1},on:{"update:visible":function($event){_vm.visible=$event}}})]:_c('simple-text',{attrs:{"show-double-quotes":_vm.showDoubleQuotes,"show-comma":_vm.notLastKey,"parent-data":_vm.parentData,"data":_vm.data,"current-key":_vm.currentKey}},[(_vm.parentData && _vm.currentKey && !Array.isArray(_vm.parentData))?_c('span',{staticClass:"vjs-key"},[_vm._v(" "+_vm._s(_vm.keyFormatter(_vm.currentKey))+": ")]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Prop, Vue } from 'vue-property-decorator';
import VueJsonPretty from '@/components/vue-json-pretty/index.js';
import { useScrollStore } from '@/stores/index';
import { formatFullRelativeDate } from '@/utils';

@Component({
  components: {
    VueJsonPretty,
  },
})
export default class SubmissionSource extends Vue {
  @Prop({ required: true })
  private readonly submission: { dataJson: string; id: string; created: string };

  private scrollStore = useScrollStore();

  private close() {
    this.$modal.hide('SubmissionSource');
    this.scrollStore.disableScroll('Source');
  }

  get date() {
    return formatFullRelativeDate(this.submission.created);
  }
}

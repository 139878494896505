
import { Component, Prop, Vue } from 'vue-property-decorator';
import useFormsSubmissions from '@/stores/forms/submissions';

@Component
export default class SubmissionsColumnHeader extends Vue {
  @Prop({ required: false, default: '' })
  private readonly title: string;

  @Prop({ required: false, default: '' })
  private readonly description: string;

  private formsSubmissions = useFormsSubmissions();

  get showTitles() {
    return this.formsSubmissions.getShowTitles;
  }
}

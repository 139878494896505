
import { Component, Vue } from 'vue-property-decorator';
import { SubmissionType } from '@/views/forms/submissions/types';
import useFormsSubmissions from '@/stores/forms/submissions';

@Component
export default class SubmissionTypeSwitcher extends Vue {
  private SubmissionType = SubmissionType;

  private formsSubmissions = useFormsSubmissions();

  private get type(): SubmissionType {
    return this.formsSubmissions.type;
  }

  private set type(value: SubmissionType) {
    this.formsSubmissions.setType(value);
  }

  private get label() {
    return this.type === SubmissionType.Submission ? 'All Submissions' : 'Drafts';
  }
}


import { AxiosError } from 'axios';
import {
  Component, Vue, Watch, Ref,
} from 'vue-property-decorator';
import { mapState } from 'pinia';
import {
  OptionsColumns,
  Options,
  RowComponent,
  TabulatorFull as Tabulator,
} from 'tabulator-tables';
import useFormsHeader from '@/stores/forms/header';
import useFormsSubmissions from '@/stores/forms/submissions';
import useFormsStore from '@/stores/forms/forms';
import Button from '@/components/help/button.vue';
import { createVueComponent } from '@/utils/index.js';

import SubmissionsColumnsSettings from '@/views/forms/submissions/modals/submissions-columns-settings.vue';
import SubmissionView from '@/views/forms/submissions/modals/submission-view.vue';
import ToolbarActions from '@/views/forms/components/table/toolbar-actions.vue';
import ToolbarButton from '@/views/forms/components/toolbar-button.vue';
import Loader from '@/views/forms/components/loader.vue';
import { Action } from '@/views/forms/components/table/types';
import SubmissionsActions from '@/views/forms/submissions/mixins/submissions-actions.vue';
import SubmissionsColumnHeader from '@/views/forms/submissions/submissions-column-header.vue';
import SubmissionsCheckbox from '@/views/forms/submissions/submissions-checkbox.vue';
import LastModifiedField from '@/views/forms/submissions/fields/last-modified-field/last-modified-field.vue';
import BaseField from '@/views/forms/submissions/fields/base-field.vue';
import SubmissionTypeSwitcher from '@/views/forms/components/submission-type-switcher.vue';
import {
  FieldTypes,
  FieldSchema,
  SettingsSchema,
  ModalSettings,
  Submission,
  SubmissionType,
} from './types';

@Component({
  components: {
    ToolbarActions,
    Button,
    ToolbarButton,
    SubmissionsColumnsSettings,
  },
  computed: {
    ...mapState(useFormsSubmissions, {
      initialSettings: 'settings',
      initialFields: 'fields',
      submissionType: 'type',
      submissions: 'getSubmissions',
      settings: 'getSettings',
      totalSubmissions: 'getTotalSubmissions',
      pageSize: 'getPageSize',
      page: 'getPage',
      sortedFields: 'getSortedFields',
      showTitles: 'getShowTitles',
      lastChunk: 'getSubmissionsLastChunk',
      selectedSubmissions: 'selectedSubmissions',
    }),
  },
})
export default class SubmissionsList extends SubmissionsActions {
  initialSettings: SettingsSchema | null;

  initialFields: FieldSchema[] | null;

  submissions: Submission[];

  selectedSubmissions: string[];

  sortedFields: FieldSchema[];

  settings: SettingsSchema['columns'] | null;

  showTitles: SettingsSchema['showTitles'];

  totalSubmissions: number;

  page: number;

  pageSize: number;

  lastChunk: Submission[];

  formsStore = useFormsStore();

  formsHeader = useFormsHeader();

  @Ref()
  private readonly tabulatorRef: HTMLDivElement & Vue;

  private table: null | Tabulator = null;

  private isTableInjected = false;

  get columns(): OptionsColumns['columns'] {
    const columns: OptionsColumns['columns'] = this.sortedFields
      .filter((field) => this.settings?.includes(field.internalName))
      .map((field) => ({
        get title() {
          const title = field.internalName.startsWith('__') ? field.title : field.internalName;
          const description = field.internalName.startsWith('__') ? null : field.title;

          return createVueComponent(SubmissionsColumnHeader, {
            propsData: {
              title,
              description,
            },
          }).$mount().$el.outerHTML;
        },
        field: field.internalName,
        headerSort: false,
        width: this.getColumnWidth(field.fieldType),
        formatter(cell) {
          return createVueComponent(BaseField, {
            propsData: {
              value: cell.getValue(),
              schema: field,
            },
          }).$mount().$el;
        },
      }));

    return [
      {
        title: '',
        titleFormatter: () => {
          const Field = createVueComponent(SubmissionsCheckbox, {
            propsData: {
              submissionId: [],
              type: 'header',
            },
          });
          return Field.$mount().$el;
        },
        headerSort: false,
        width: 44,
        frozen: true,
        resizable: false,
        formatter: (cell) => {
          const Field = createVueComponent(SubmissionsCheckbox, {
            propsData: {
              submissionId: cell.getRow().getData().__id,
              type: 'cell',
            },
          });
          return Field.$mount().$el;
        },
      },
      {
        title: '',
        field: 'lastModified',
        headerSort: false,
        frozen: true,
        width: 200,
        formatter: (cell) => {
          const Field = createVueComponent(LastModifiedField, {
            propsData: {
              value: cell.getValue(),
              element: cell.getRow().getElement(),
              submissionId: cell.getRow().getData().__id,
            },
          });

          return Field.$mount().$el;
        },
      },
      ...columns,
    ];
  }

  get toolbarActions(): Action[] {
    const set = new Set(this.selectedSubmissions);
    const selectedRows = this.submissions.filter((submission) => set.has(submission.__id));

    return [
      {
        id: '0',
        icon: ['fal', 'eye'],
        title: 'View',
        visible: selectedRows.length === 1,
        handler: async () => {
          const settings: ModalSettings = {
            component: SubmissionView,
            props: {
              submission: selectedRows[0],
            },
            modalProps: {
              name: 'SubmissionView',
              clickToClose: true,
            },
          };

          this.viewModal(settings);
        },
      },
      {
        id: '1',
        icon: ['fal', 'envelope'],
        title: 'Unread',
        visible: Boolean(selectedRows.length) && selectedRows.every((row) => row.isRead),
        handler: async () => {
          await this.formsSubmissions.unreadSubmissions({ ids: this.selectedSubmissions });
        },
      },
      {
        id: '2',
        icon: ['fal', 'envelope-open'],
        title: 'Read',
        visible: selectedRows.some((row) => !row.isRead),
        handler: async () => {
          await this.formsSubmissions.readSubmissions({ ids: this.selectedSubmissions });
        },
      },
      {
        id: '3',
        icon: ['fal', 'trash-can'],
        title: 'Delete',
        visible: Boolean(selectedRows.length),
        handler: async () => {
          await this.deleteSubmissions(this.selectedSubmissions);
        },
      },
    ];
  }

  get exportSettings() {
    const allRowsSelected = this.selectedSubmissions.length === this.table?.getRows().length;
    const exportAll = allRowsSelected || !this.selectedSubmissions.length;

    return {
      title: exportAll ? 'Export All' : 'Export',
      handler: exportAll ? this.exportAll : this.export,
      disabled: !this.submissions.length,
    };
  }

  get lastPage() {
    return Math.ceil(this.totalSubmissions / this.pageSize);
  }

  get Loader() {
    return createVueComponent(Loader).$mount().$el;
  }

  get breadcrumbs() {
    return this.formsHeader.breadcrumbs;
  }

  private getColumnWidth(field: FieldTypes) {
    switch (field) {
      case FieldTypes.Account:
        return 200;
      case FieldTypes.LikertScale:
        return 300;
      case FieldTypes.Attachments:
      case FieldTypes.SingleLineText:
      case FieldTypes.TextBox:
      case FieldTypes.MultiLineText:
      case FieldTypes.MultilineTextBox:
      case FieldTypes.Signature:
      case FieldTypes.DataTable:
        return 400;
      default:
        return undefined;
    }
  }

  private async loadData() {
    try {
      await Promise.all([
        this.formsStore.loadForm({ formId: this.formId }).then((response) => {
          this.formsHeader.pageTitle = response.data.name;
          this.formsHeader.setPageBreadcrumb({
            name: 'Forms_Forms_Submissions',
            title: response.data.name,
          });
        }),

        this.formsSubmissions.loadData(),
      ]);
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 404) {
        console.error(`Form ${this.formId} not found`);
        this.$router.push('/forms/forms');
        return;
      }
      throw e;
    }
  }

  private refresh() {
    this.isTableInjected = false;
    this.formsSubmissions.cancelPendingRequests();
    this.formsSubmissions.clearSubmissions();
    this.formsSubmissions.deselectSubmission();
    this.createTable();
  }

  private async createTable() {
    this.table = new Tabulator(this.tabulatorRef, {
      index: '__id',
      rowHeight: 48,
      dataLoaderLoading: this.Loader,
      columns: this.columns,
      progressiveLoad: 'scroll',
      ajaxURL: 'ajaxURL',
      layout: 'fitDataStretch',
      ajaxRequestFunc: () => {
        const loadData = async () => {
          try {
            await this.formsSubmissions.loadSubmissionsPage();

            return {
              last_page: this.lastPage,
              data: this.lastChunk.map((s) => ({ ...s })),
              current_page: this.page,
            };
          } catch {
            throw new Error('Failed to load data for the Submissions Table');
          }
        };

        return loadData();
      },
      persistenceID: `${this.formId}-key`,
      persistence: {
        columns: ['width'],
      },
      layoutColumnsOnNewData: true,
      rowFormatter: (row) => {
        this.highlightSubmission(row);
        return row;
      },
    } as Options & { rowHeight: number });

    this.table.on('tableBuilt', () => {
      this.isTableInjected = true;
    });

    this.table.on('rowClick', (e, row) => {
      const { __id } = row.getData();
      if (!row.isSelected()) {
        return this.formsSubmissions.selectSubmission(__id);
      }

      return this.formsSubmissions.deselectSubmission(__id);
    });
  }

  private highlightSubmission(row: RowComponent) {
    if (!row.getData().isRead) {
      return row.getElement().classList.add('tabulator-row_unread');
    }
    return row.getElement().classList.remove('tabulator-row_unread');
  }

  private async export() {
    await this.formsSubmissions.exportSelected({
      ids: this.selectedSubmissions,
    });
  }

  private async exportAll() {
    await this.formsSubmissions.exportAll();
  }

  private showColumnsSettings() {
    this.$modal.show(
      SubmissionsColumnsSettings,
      {
        formId: this.formId,
      },
      {
        name: 'SubmissionsColumnsSettings',
        clickToClose: true,
        adaptive: true,
        maxWidth: 605,
        width: '100%',
        draggable: '.draggable',
        height: 'auto',
        transition: 'fade',
      },
    );
  }

  @Watch('submissions')
  private onSubmissionsChange(submissions: Submission[]) {
    const { selectedSubmissions, table, isTableInjected } = this;
    if (table && isTableInjected) {
      const tableRows = table.getRows();

      if (submissions.length <= tableRows.length) {
        // use two pointners algo to effectively update table data
        let p1 = 0;
        let p2 = 0;
        while (p2 < tableRows.length) {
          const row = tableRows[p2];
          const rowData = row.getData();

          if (submissions[p1]?.__id !== rowData.__id) {
            table.deleteRow(row);
            p2++;
            continue;
          }

          if (
            rowData.isRead !== submissions[p1].isRead
            || rowData.lastModified !== submissions[p1].lastModified
          ) {
            table.updateRow(row, submissions[p1]);
          }

          p1++;
          p2++;
        }
      }

      setTimeout(() => table.selectRow(selectedSubmissions));
    }
  }

  @Watch('settings')
  private onColumnsSettingsChange() {
    if (this.table && this.isTableInjected && this.columns) {
      this.table.setColumns(this.columns);
    }
  }

  @Watch('initialSettings')
  @Watch('initialFields')
  @Watch('breadcrumbs')
  private dataForTableIsReady() {
    if (this.initialFields && !this.isTableInjected && this.breadcrumbs.length) {
      this.createTable();
    }
  }

  @Watch('selectedSubmissions')
  private onSelectedSubmissions(value: string[]) {
    this.table?.deselectRow();
    this.table?.selectRow(value);
  }

  @Watch('submissionType')
  private onTypeChange(value: SubmissionType) {
    this.$nextTick(() => {
      this.formsSubmissions.cancelPendingRequests();
      this.formsSubmissions.setType(value);
      this.refresh();
    });
  }

  public created() {
    this.formsHeader.levelbarActionsComponent = SubmissionTypeSwitcher;
    this.loadData();
  }

  public beforeDestroy() {
    this.formsHeader.levelbarActionsComponent = null;
    this.formsSubmissions.cancelPendingRequests();
    this.formsSubmissions.clear();
  }
}

<template>
  <router-view v-if="submissionId" />
  <submissions-list v-else />
</template>

<script>
import SubmissionsList from './submissions-list.vue';

export default {
  name: 'SubmissionsPage',
  components: { SubmissionsList },

  data() {
    return {
      submissionId: null,
    };
  },

  watch: {
    '$route.params.submissionId': {
      handler() {
        this.submissionId = this.$route.params.submissionId;
      },
      immediate: true,
    },
  },
};
</script>

export default {
    props: {
        visible: {
            required: true,
            type: Boolean
        },
        data: {
            required: true
        },
        showComma: Boolean,
        canCollapse: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    computed: {
        dataVisiable: {
            get () {
                return this.visible
            },
            set (val) {
                this.$emit('update:visible', val)
            }
        }
    },
    methods: {
        toggleBrackets () {
            this.dataVisiable = this.canCollapse ? !this.dataVisiable : true;
        },
        bracketsFormatter (brackets) {
            return this.showComma ? `${brackets},` : brackets
        }
    }
}

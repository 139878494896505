
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import useFormsSubmissions from '@/stores/forms/submissions';

@Component
export default class SubmissionsCheckbox extends Vue {
  @Prop({ required: true })
  private readonly submissionId: string;

  @Prop({ default: 'cell' })
  private readonly type: 'header' | 'cell';

  private checked = false;

  private formsSubmissions = useFormsSubmissions();

  get selectedIds() {
    return this.formsSubmissions.selectedSubmissions;
  }

  get indeterminate() {
    return (
      Boolean(this.selectedIds.length)
      && this.selectedIds.length !== this.formsSubmissions.getSubmissions.length
    );
  }

  private onCheckboxClick() {
    if (this.type === 'header') {
      return !this.checked
        ? this.formsSubmissions.selectSubmission()
        : this.formsSubmissions.deselectSubmission();
    }
    return null;
  }

  @Watch('selectedIds', { immediate: true })
  private onChange(value: string[]) {
    if (this.type === 'cell') {
      this.checked = value.includes(this.submissionId);
    }

    if (this.type === 'header') {
      const hasSubmissions = this.formsSubmissions.getSubmissions.length !== 0;
      this.checked = hasSubmissions && value.length === this.formsSubmissions.getSubmissions.length;
    }
  }
}

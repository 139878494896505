
import {
  Component, Vue, Prop, Ref,
} from 'vue-property-decorator';
import { mapState } from 'pinia';
import draggable from 'vuedraggable';
import useFormsSubmissions from '@/stores/forms/submissions';
import { FieldSchema, SettingsSchema, ViewSettingsSchema } from '@/views/forms/submissions/types';
import Loader from '@/views/forms/components/loader.vue';

@Component({
  computed: {
    ...mapState(useFormsSubmissions, {
      sortedFields: 'getSortedFields',
      showTitles: 'getShowTitles',
      settings: 'getSettings',
    }),
  },
  components: {
    draggable,
    Loader,
  },
})
export default class SubmissionsColumnsSettings extends Vue {
  @Prop({ required: true })
  private readonly formId: string;

  @Ref()
  private readonly draggableRef: Vue;

  sortedFields: FieldSchema[];

  settings: SettingsSchema['columns'];

  showTitles: SettingsSchema['showTitles'];

  private viewSettings: ViewSettingsSchema[] = [];

  private uploading = false;

  private areTitlesShown: SettingsSchema['showTitles'];

  private formsSubmissions = useFormsSubmissions();

  private close() {
    this.$modal.hide('SubmissionsColumnsSettings');
  }

  get selectedFields() {
    return this.viewSettings
      .filter((setting) => setting.selected)
      .map((setting) => setting.internalName);
  }

  get draggableOptions() {
    return this.isPortableDevice ? { handle: '.draggable-area' } : {};
  }

  private async saveSettings() {
    this.uploading = true;
    try {
      await this.formsSubmissions.saveSettings({
        selectedFields: this.selectedFields,
        showTitles: this.areTitlesShown,
      });
    } finally {
      this.close();
      await this.formsSubmissions.loadSettings();
    }
  }

  private onSwitchChange(index: number) {
    this.viewSettings[index].selected = !this.viewSettings[index].selected;
  }

  private generateViewSettings() {
    const fields: ViewSettingsSchema[] = this.sortedFields.map((field) => ({
      internalName: field.internalName,
      alias: field.internalName.startsWith('__') ? field.title : field.internalName,
      description: field.internalName.startsWith('__') ? null : field.title,
      selected: this.settings.includes(field.internalName),
    }));

    return [...fields].sort((a, b) => Number(b.selected) - Number(a.selected));
  }

  created() {
    this.viewSettings = this.generateViewSettings();
    this.areTitlesShown = this.showTitles;
  }

  // Below is a complicated solution to the problem
  // of the overlap area (hover, mouseenter) of elements in the DnD API of the Chromium browsers
  // See more: https://bugs.webkit.org/show_bug.cgi?id=134555
  //           https://bugs.chromium.org/p/chromium/issues/detail?id=410328

  get isPortableDevice(): boolean {
    return 'ontouchstart' in document.documentElement && /Mobi/.test(navigator.userAgent);
  }

  private isDragged = false;

  private preventMouseEvents = false;

  private onStart(e: { item: HTMLElement }) {
    if (!this.isPortableDevice) {
      this.isDragged = true;
      e.item.classList.add('dragged-item');
    }
  }

  private async onEnd(e: { item: HTMLElement }) {
    if (!this.isPortableDevice) {
      e.item.classList.add('dragged-item');
      this.preventMouseEvents = true;
      this.isDragged = false;
    }
  }

  private onMouseEnter(e: MouseEvent & { target: HTMLElement }) {
    if (!this.isDragged && !this.preventMouseEvents) {
      e.target.classList.add('dragged-item');
    } else {
      this.preventMouseEvents = false;
    }
  }

  private onMouseLeave(e: MouseEvent & { target: HTMLElement }) {
    if (!this.isDragged && !this.preventMouseEvents) {
      e.target.classList.remove('dragged-item');
    }
  }
}

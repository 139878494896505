
import {
  Vue, Component, Ref, Prop,
} from 'vue-property-decorator';
import { useScrollStore } from '@/stores/index';
import SubmissionSource from '@/views/forms/submissions/modals/submission-source.vue';
import SubmissionForm from '@/views/forms/submissions/modals/submission-form/submission-form.vue';
import SubmissionView from '@/views/forms/submissions/modals/submission-view.vue';
import SubmissionsActions from '@/views/forms/submissions/mixins/submissions-actions.vue';
import { ModalSettings, Submission } from '@/views/forms/submissions/types';

@Component
export default class LastModifiedDropdown extends SubmissionsActions {
  @Prop({ required: true })
  private readonly submission: Submission;

  private scrollStore = useScrollStore();

  @Ref()
  private readonly dropdownRef: {
    toggle: () => void;
    isActive: boolean;
    $refs: {
      dropdownMenu: {
        firstChild: HTMLElement;
      };
    };
  } & Vue &
    HTMLElement;

  public isActive = false;

  private observer: IntersectionObserver | null = null;

  private position: 'top' | 'bottom' = 'bottom';

  get contextMenuActions() {
    if (!this.submission) return [];
    return [
      {
        id: '0',
        visible: true,
        icon: ['far', 'eye'],
        text: 'View',
        bold: true,
        handler: async () => {
          const settings: ModalSettings = {
            component: SubmissionView,
            props: {
              submission: this.submission,
            },
            modalProps: {
              name: 'SubmissionView',
              clickToClose: true,
              draggable: '.draggable',
              adaptive: true,
            },
          };
          this.viewModal(settings);
        },
      },
      {
        id: '1',
        visible: true,
        icon: ['fal', 'pen-field'],
        text: 'Form',
        handler: async () => {
          const settings: ModalSettings = {
            component: SubmissionForm,
            props: {
              submission: this.submission,
            },
            modalProps: {
              name: 'SubmissionForm',
            },
          };

          this.viewModal(settings);
        },
      },
      {
        id: '2',
        visible: true,
        icon: ['fal', 'code'],
        text: 'Source',
        handler: () => {
          const modalSettings: ModalSettings = {
            component: SubmissionSource,
            props: {
              submission: this.submission,
            },
            modalProps: {
              name: 'SubmissionSource',
            },
          };

          this.scrollStore.enableScroll('Source');
          this.viewModal(modalSettings);
        },
      },
      {
        id: '3',
        visible: !this.submission.isRead,
        icon: ['fal', 'envelope-open'],
        text: 'Read',
        handler: async () => {
          await this.formsSubmissions.readSubmissions({ ids: [this.submission.__id] });
        },
      },
      {
        id: '4',
        visible: this.submission.isRead,
        icon: ['fal', 'envelope'],
        text: 'Unread',
        handler: async () => {
          await this.formsSubmissions.unreadSubmissions({ ids: [this.submission.__id] });
        },
      },
      {
        id: '5',
        visible: true,
        icon: ['fal', 'trash-can'],
        text: 'Delete',
        handler: async () => {
          await this.deleteSubmissions([this.submission.__id]);
        },
      },
    ];
  }

  private setDropdown(e: boolean) {
    this.isActive = e;
  }

  public toggle() {
    this.dropdownRef.toggle();
  }

  public async mounted() {
    if (!this.observer) {
      await this.$nextTick();

      const options = {
        root: document.querySelector('.tabulator'),
      };

      const handler = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio < 1 && this.isActive) {
            this.position = this.position === 'bottom' ? 'top' : 'bottom';
          }
        });
      };

      this.observer = new IntersectionObserver(handler, options);
      this.observer.observe(this.dropdownRef.$refs.dropdownMenu.firstChild);
    }
  }

  public beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.dropdownRef.$refs.dropdownMenu.firstChild);
    }
  }
}

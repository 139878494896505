
import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { mapState } from 'pinia';
import useFormsSubmissions from '@/stores/forms/submissions';
import ToolbarButton from '@/views/forms/components/toolbar-button.vue';
import { Submission, SubmissionType } from '@/views/forms/submissions/types';
import { ensureFormsScript } from '@/utils/index.js';

@Component({
  components: {
    ToolbarButton,
  },
  computed: {
    ...mapState(useFormsSubmissions, ['type']),
  },
})
export default class SubmissionFormContent extends Vue {
  @Ref()
  private readonly formRef: HTMLElement;

  @Prop({ required: true })
  private readonly formId: string;

  @Prop({ required: true })
  private readonly submission: Submission;

  private type: SubmissionType;

  private fd: any | null = null;

  private formName = 'form';

  public formReady = false;

  private formsSubmissions = useFormsSubmissions();

  @Watch('submission')
  @Watch('formReady')
  private onSubmissionChange() {
    this.trySetSubmissionData();
  }

  private trySetSubmissionData() {
    if (this.submission && this.formReady && this.type === SubmissionType.Submission) {
      this.fd.data(JSON.parse(this.submission.dataJson));
      if (this.fd.singleSubmissionPerUser) {
        this.fd.submissionId = this.submission.__id;
      }
    }
  }

  public exportPdf() {
    return this.fd.exportToPDF(`${this.formName}.pdf`);
  }

  public async mounted() {
    await ensureFormsScript();

    const { Plumsail } = window as any;
    if (Plumsail) {
      this.fd = new Plumsail.Form(this.formRef, this.formId, {
        adminAxios: this.axios,
        lastSubmission: { id: this.submission.__id, isDraft: this.type === SubmissionType.Draft },
        saved: () => {
          if (this.type === SubmissionType.Draft) {
            this.formsSubmissions.removeSubmissions({ ids: [this.submission.__id] });
            this.$emit('removed');
          }
        },
        draftSaved: () => {
          this.formsSubmissions.refreshSubmission({ id: this.submission.__id });
        },
      });
      if (this.fd) {
        this.fd.rendered(() => {
          this.fd.clear();
          this.formReady = true;
        });
      }
    }
  }
}

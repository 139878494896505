<template>
  <div>
    <slot></slot>
    <span v-if="isLink">"<a :href="text" target="_blank" @click.stop><u>{{ text }}</u></a>"</span>
    <span v-else :class="`vjs-value vjs-value__${dataType}`">{{ formattedText }}</span>
    <span v-if="showComma">,</span>
  </div>
</template>

<script>
import { getDataType } from '../utils';

export default {
  props: {
    showDoubleQuotes: Boolean,
    parentData: {},
    data: {},
    showComma: Boolean,
    currentKey: [Number, String]
  },
  computed: {
    dataType() {
      return getDataType(this.data);
    },
    parentDataType() {
      return getDataType(this.parentData);
    },
    text() {
      return this.data.toString();
    },
    formattedText() {
        if (this.dataType === 'string') 
            return `"${this.text}"`
        
        return this.text;
    },
    isLink() {
      return this.dataType === 'string' && this.text.match(/^https?:\/\//);
    }
  },
};
</script>

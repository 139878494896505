import { render, staticRenderFns } from "./submission-type-switcher.vue?vue&type=template&id=07be171f&scoped=true&"
import script from "./submission-type-switcher.vue?vue&type=script&lang=ts&"
export * from "./submission-type-switcher.vue?vue&type=script&lang=ts&"
import style0 from "./submission-type-switcher.vue?vue&type=style&index=0&id=07be171f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07be171f",
  null
  
)

export default component.exports
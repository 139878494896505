
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator';
import { createVueComponent, formatRelativeDate } from '@/utils/index.js';
import LastModifiedDropdown from './last-modified-dropdown.vue';
import SubmissionsActions from '../../mixins/submissions-actions.vue';

@Component
export default class LastModifiedField extends SubmissionsActions {
  @Prop({ required: true })
  private readonly value: string;

  @Prop({ required: true })
  private readonly submissionId: string;

  @Prop({ required: true })
  private readonly element: HTMLElement;

  @Ref()
  private readonly dropdownWrapperRef: HTMLDivElement;

  private Dropdown: (Vue & { toggle: () => void; isActive: () => boolean }) | null = null;

  private dropdownIsLoaded = false;

  get parsedLastModified() {
    return formatRelativeDate(new Date(this.value));
  }

  get dropdownIsActive() {
    return this.Dropdown?.isActive;
  }

  get submission() {
    return this.formsSubmissions.getSubmissions.find(
      (submission) => submission.__id === this.submissionId,
    );
  }

  get isRead() {
    return this.submission?.isRead;
  }

  private onContextMenuClick() {
    if (!this.dropdownIsLoaded) {
      this.createDropdown();
    }

    this.Dropdown?.toggle();
    document.body.click();

    this.dropdownIsLoaded = true;
  }

  private createDropdown() {
    this.Dropdown = createVueComponent(LastModifiedDropdown, {
      propsData: {
        submission: this.submission,
      },
    });

    this.Dropdown?.$mount(this.dropdownWrapperRef);
  }

  private onTimeClick(e: MouseEvent) {
    if (this.submission) {
      this.showView({ e, options: this.submission });
    }
  }

  @Watch('dropdownIsActive')
  private async onDropdownChange(value: boolean) {
    await this.$nextTick();

    if (value) {
      return this.element.classList.add('tabulator-row-hovered');
    }
    return this.element.classList.remove('tabulator-row-hovered');
  }

  @Watch('isRead')
  private onReadChange() {
    if (this.dropdownIsLoaded && this.Dropdown) {
      this.Dropdown.$set(this.Dropdown, 'submission', this.submission);
    }
  }
}

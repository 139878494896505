
import { Component, Vue } from 'vue-property-decorator';
import useFormsSubmissions from '@/stores/forms/submissions';
import SubmissionView from '@/views/forms/submissions/modals/submission-view.vue';
import { ModalSettings, Submission } from '@/views/forms/submissions/types';

@Component
export default class SubmissionsActions extends Vue {
  formsSubmissions = useFormsSubmissions();

  get formId(): string {
    return this.formsSubmissions.getFormId;
  }

  public async showView({ e, options }: { e: MouseEvent; options: Submission }) {
    if (e.ctrlKey) {
      return this.openView(options);
    }
    const modalSettings: ModalSettings = {
      component: SubmissionView,
      props: {
        submission: options,
      },
      modalProps: {
        name: 'SubmissionView',
        clickToClose: true,
      },
    };

    return this.viewModal(modalSettings);
  }

  private openView({ isRead, __id }: Submission) {
    if (!isRead && __id) {
      this.formsSubmissions.readSubmissions({ ids: [__id] });
    }

    const formUrl = `${window.location.origin}/forms/forms/${this.formId}/${__id}?type=${this.formsSubmissions.type}`;

    window.open(formUrl, '_blank');
  }

  public async viewModal({ component, props, modalProps = {} }: ModalSettings) {
    const options = {
      height: 'auto',
      width: window.innerWidth < 768 ? '100%' : window.innerWidth - 200,
      adaptive: true,
      clickToClose: false,
      draggable: '.draggable',
      ...modalProps,
    };

    this.$modal.show(component, props, options);
    if (!props.submission.isRead && props.submission.__id) {
      await this.formsSubmissions.readSubmissions({ ids: [props.submission.__id] });
    }
  }

  public async deleteSubmissions(selectedIds: string[]) {
    const confirmationText = selectedIds.length === 1
      ? 'Are you sure you want to delete the selected record?'
      : 'Are you sure you want to delete the selected records?';

    const isConfirmed = window.confirm(confirmationText);

    if (isConfirmed) {
      await this.formsSubmissions.deleteSubmissions({ ids: selectedIds });
    }
  }
}

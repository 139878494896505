
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator';
import { mapState } from 'pinia';
import useFormsSubmissions from '@/stores/forms/submissions';
import SubmissionFormContent from '@/views/forms/submissions/modals/submission-form/submission-form-content.vue';
import { formatFullRelativeDate } from '@/utils';
import { Submission } from '@/views/forms/submissions/types';

@Component({
  components: {
    SubmissionFormContent,
  },
  computed: {
    ...mapState(useFormsSubmissions, {
      formId: 'getFormId',
    }),
  },
})
export default class SubmissionForm extends Vue {
  formId: string;

  @Ref()
  private readonly formRef: SubmissionFormContent;

  @Prop({ required: true })
  private readonly submission: Submission;

  private exportInProgress = false;

  get date() {
    return formatFullRelativeDate(this.submission.created);
  }

  private close() {
    this.$modal.hide('SubmissionForm');
  }

  private async exportToPDF() {
    if (this.formRef.formReady) {
      this.exportInProgress = true;
      this.formRef.exportPdf().finally(() => {
        this.exportInProgress = false;
      });
    }
  }
}
